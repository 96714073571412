import React from 'react';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    Link as LinkMaterial,
    makeStyles,
    Typography
} from '@material-ui/core';
import clsx from "clsx";
import globalTheme from "../../../../../../../../theme/globalTheme";
import CorrespondenceQuoteSegment from "../../../segments/Correspondence";
import BasicInformationQuoteSegment from "../../../segments/BasicInformation";
import ServiceChargeQuoteSegment from "../../../segments/ServiceCharge";
import NotesQuoteSegment from "../../../segments/Notes";
import {Link} from "react-router-dom";
import Auth0Utils from "../../../../../../../../../state/utils/Auth0Utils";
import {useAuth0} from "@auth0/auth0-react";
import EditableCheck from "../../../../../../../../atoms/EditableCheck";
import {empty} from "../../../../../../../../../state/utils/Common";
import NotEditableCheck from "../../../../../../../../atoms/NotEditableCheck";
import {Trans} from "react-i18next";
import QuotePDFViewer from "../../../segments/QuotePDFViewer";
import OptionInputBlocks
    from "../../../../../../../../../state/utils/data/request_application/option_input_blocks.json";
import AdditionalTermsContainer
    from "../../../../../../../../containers/user/requests/detail/quote/apply/Terms/AdditionalTermsContainer";
import services from "../../../../../../../../../state/utils/data/services.json";
import CapicoAgreementCheckField from "../../../../../../../../atoms/AgreementCheckField/CapicoAgreementCheckField";
import AdebisAgreementCheckField from "../../../../../../../../atoms/AgreementCheckField/AdebisAgreementCheckField";
import SpotAgreementCheckField from "../../../../../../../../atoms/AgreementCheckField/SpotAgreementCheckField";
import SpotGspPaiedAgreementCheckField from "../../../../../../../../atoms/AgreementCheckField/SpotGspPaiedAgreementCheckField";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const ConfirmView = (
    {
        contractUpdateRequest,
        requestQuote,
        requestApplyAcceptTerms,
        applyToggleTerms,

        hasScrolledToTermsBottom,

        settingApplyConfirm,
        additionalOptionInformation,
        requestDetail,
        termsAndConditionsIsOpen,
        closeTermsAndConditionsModal,
        openTermsAndConditionsModal,
        managerProfile,
        backToAdditionalSteps,
        updateRequiresOptionInformation,
        advertAgentInformation,
        formattedAdditionalOptionInformation,
        quoteId,
        requestId,
        requestGroupId,

        pdfDataBlobPreview,
        pdfDataBlobPreviewLoading,

        t
    }) => {
    const classes = useStyles();
    const globalClass = globalTheme._default();
    const {getAccessTokenSilently} = useAuth0();
    
    
    
    return (
        <>
            <Box className={clsx(classes.root)}>
                <Typography
                    variant="body1">{t('requests.detail.quotes.apply.step_1_confirm.description')}</Typography>
            </Box>
            <Divider/>
            <CorrespondenceQuoteSegment requestQuote={requestQuote} requestDetail={requestDetail} t={t}/>
            <Divider/>
            <BasicInformationQuoteSegment requestQuote={requestQuote} t={t}/>
            <Divider/>
            {!empty(pdfDataBlobPreview) && pdfDataBlobPreviewLoading === false &&
                <QuotePDFViewer pdfDataBlobPreview={pdfDataBlobPreview} t={t} />
            }
            <Divider/>
            <NotesQuoteSegment
                requestQuote={requestQuote}
                t={t}
                requestDetail={requestDetail}
                termsAndConditionsIsOpen={termsAndConditionsIsOpen}
                closeTermsAndConditionsModal={closeTermsAndConditionsModal}
                openTermsAndConditionsModal={openTermsAndConditionsModal}
                isModal={false}
                hasScrolledToTermsBottom={hasScrolledToTermsBottom}
            />
            {!empty(formattedAdditionalOptionInformation) &&
                <>
                    {Object.keys(formattedAdditionalOptionInformation).map((value) => {
                        return (
                            <>
                                <Divider style={{marginTop: 10}}/>
                                <Box className={clsx(classes.root)}>
                                    <Grid container style={{marginTop: 0}}>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="body1"
                                                        className={classes.strong}>{t('additional_option_label.form_block_headers.' + value)}</Typography>
                                            {Object.keys(formattedAdditionalOptionInformation[value]).map((value2) => {
                                                return (
                                                    <Grid container style={{margin: "10px 0"}}>
                                                        <Grid item xs={4} sm={1}>
                                                            <Typography variant="body1"
                                                                        className={classes.strong}>{t('additional_option_label.form_labels.' + value2)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={8} sm={11} className={globalClass.detail_items}>
                                                            <Typography style={{ whiteSpace: "pre-wrap" }}
                                                                        variant={"body1"}>{formattedAdditionalOptionInformation[value][value2]}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })}
                                            {!empty(OptionInputBlocks[value]) && 'terms_of_service_html' in OptionInputBlocks[value] &&
                                                <Grid container style={{margin: "10px 0"}}>
                                                    <Grid item xs={4} sm={1}>
                                                        <Typography variant="body1"
                                                                    className={classes.strong}>{t('additional_option_label.form_labels.' + value + '.terms')}</Typography>
                                                    </Grid>
                                                    <Grid item xs={8} sm={11} className={globalClass.detail_items}>
                                                        <AdditionalTermsContainer></AdditionalTermsContainer>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                        );
                    })}
                </>
            }
            {/* @TODO: IF分岐を[n]AgreementCheckFieldみたいな感じに変更 */}
            { requestDetail.service_name === services.ADEBIS &&
                <AdebisAgreementCheckField
                    classes={classes}
                    hasScrolledToTermsBottom={hasScrolledToTermsBottom}
                    applyToggleTerms={applyToggleTerms}
                    requestApplyAcceptTerms={requestApplyAcceptTerms}
                    t={t}
                />
            }
            { requestDetail.service_name === services.CAPICO &&
                <CapicoAgreementCheckField
                    classes={classes}
                    hasScrolledToTermsBottom={hasScrolledToTermsBottom}
                    applyToggleTerms={applyToggleTerms}
                    requestApplyAcceptTerms={requestApplyAcceptTerms}
                    t={t}
                />
            }
            { (requestDetail.service_name === services.SPOT && requestQuote.is_gsp_paied === false) &&
                <SpotAgreementCheckField
                    classes={classes}
                    hasScrolledToTermsBottom={hasScrolledToTermsBottom}
                    applyToggleTerms={applyToggleTerms}
                    requestApplyAcceptTerms={requestApplyAcceptTerms}
                    t={t}
                />
            }
            { (requestDetail.service_name === services.SPOT && requestQuote.is_gsp_paied === true) &&
                <SpotGspPaiedAgreementCheckField
                    classes={classes}
                    hasScrolledToTermsBottom={hasScrolledToTermsBottom}
                    applyToggleTerms={applyToggleTerms}
                    requestApplyAcceptTerms={requestApplyAcceptTerms}
                    t={t}
                />
            }
            
            <Box className={clsx(classes.root)} marginY={1} alignContent="middle">
                <Grid container justify="center" alignItems={"center"} spacing={5}>
                    {empty(advertAgentInformation) &&
                        <Link
                            to={'/requests/' + requestGroupId + "/" + requestId + "/quotes/" + quoteId + '/'}>
                            <Button variant={"contained"} color={"secondary"}
                                    style={{marginRight: 20, fontWeight: 900}}>
                                {t('actions.back')}
                            </Button>
                        </Link>
                    }
                    {!empty(advertAgentInformation) &&
                        <Button variant={"contained"} color={"secondary"} onClick={() => backToAdditionalSteps()}
                                style={{marginRight: 20, fontWeight: 900}}>
                            {t('actions.back')}
                        </Button>
                    }
                    <EditableCheck>
                        <Button variant={"contained"} style={{fontWeight: 900}}
                                color={"primary"}
                                disabled={!requestApplyAcceptTerms || (requestDetail.service_name === services.ADEBIS && !hasScrolledToTermsBottom)}
                                onClick={() => (async () => {
                                    const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                        () => {
                                            return getAccessTokenSilently();
                                        },
                                    );

                                    contractUpdateRequest(access_token, managerProfile?.account?.uuid, requestGroupId, requestId, quoteId, additionalOptionInformation)
                                })()}
                        >
                            {t('actions.send')}
                        </Button>
                    </EditableCheck>
                    <NotEditableCheck>
                        <Button variant={"contained"} style={{fontWeight: 900}}
                                color={"primary"}
                                disabled={true}>
                            {t('actions.send')}
                        </Button>
                    </NotEditableCheck>
                </Grid>
            </Box>
        </>
    );
}

export default ConfirmView;
